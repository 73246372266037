import z from "zod";
import { useRef, useState, useEffect } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Tab as HeadlessTab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import ExitDialog from "./ExitDialog";
import { Button } from "../../components/Button";
import { Header } from "../../components/Header";
import { FormProvider, useForm } from "react-hook-form";
import IdentificationTab from "./IdentificationTab";
import GeneralTab from "./GeneralTab";
import TechnicalTab from "./TechnicalTab";
import RecommendationsTab from "./RecommendationsTab";
import { Page } from "../../components/Page";
import { calculateCompleted } from "../../lib/helper";
import { saveElevator } from "../../fetchers/elevators";
import {
  conditionOptions,
  Elevator,
  existOpportunities,
  upgradeNeededOpportunities,
} from "../../models/elevator";
import { zodResolver } from "@hookform/resolvers/zod";
import { RecommendationForm } from "../../models/survey";
import {
  repairRecommendationSchemaWithoutId,
  dataDrivenSchemaWithoutId,
} from "../../models/opportunity";
import { FloatingErrorMessage } from "../../components/FloatingErrorMessage";
import { useOpportunities } from "../../fetchers/opportunities";
import { columnHeaderMap } from "../sales_genie/exploreColumnHelper";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

type TabProps = {
  header: string | JSX.Element;
  subHeader?: string;
  hasErrors?: boolean;
  ariaLabel: string;
};

export const SurveyFormVm = Elevator.extend({
  recommendation: RecommendationForm.nullable().optional(),
});
export type SurveyFormVm = z.infer<typeof SurveyFormVm>;

const TESTING_SCHEDULE = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1),
);

const identificationErrorMessages = {
  name: "Name is required.",
  unitNumber: "Unit Number is required.",
  ahj: "AHJ is required.",
  ahjId: "AHJ # is required.",
};

const generalErrorMessages = {
  systemType: "System Type is required.",
  facilityType: "Facility Type is required.",
  doorType: "Door Type is required.",
  landings: "Number of Landings must be greater then 0.",
  fixturePictures: "At least one picture is required.",
  condition: "Condition is required.",
  isThereAMachineRoom: "This answer is required.",
};

const errorMessages = {
  ...identificationErrorMessages,
  ...generalErrorMessages,
};

export const SurveyFormValidation = SurveyFormVm.omit({
  name: true,
  unitNumber: true,
  ahj: true,
  ahjId: true,
  systemType: true,
  facilityType: true,
  doorType: true,
  landings: true,
  fixturePictures: true,
  condition: true,
  isThereAMachineRoom: true,
}).extend({
  name: z.string({ message: errorMessages.name }).min(1, errorMessages.name),
  unitNumber: z
    .string({ message: errorMessages.unitNumber })
    .min(1, errorMessages.unitNumber),
  ahj: z.string({ message: errorMessages.ahj }).min(1, errorMessages.ahj),
  ahjId: z.string({ message: errorMessages.ahjId }).min(1, errorMessages.ahjId),
  systemType: z
    .string({ message: errorMessages.systemType })
    .min(1, errorMessages.systemType),
  facilityType: z
    .string({ message: errorMessages.facilityType })
    .min(1, errorMessages.facilityType),
  doorType: z
    .string({ message: errorMessages.doorType })
    .min(1, errorMessages.doorType),
  landings: z.coerce.number().int().gt(0, errorMessages.landings),
  fixturePictures: z
    .array(z.string(), { message: errorMessages.fixturePictures })
    .min(1, errorMessages.fixturePictures),
  condition: z.enum(conditionOptions, {
    message: errorMessages.condition,
  }),
  isThereAMachineRoom: z.boolean({
    message: errorMessages.isThereAMachineRoom,
  }),
});
type SurveyFormValidation = z.infer<typeof SurveyFormValidation>;

const percentageFormatter = (completed: number, total: number): string => {
  return `${((completed / total) * 100).toFixed(0)}%`;
};

const Tab = ({ header, subHeader, hasErrors, ariaLabel }: TabProps) => {
  const ref = useRef<HTMLElement>(null);
  const borderColor = hasErrors ? "border-b-error-icon" : "border-b-main";
  return (
    <HeadlessTab
      ref={ref}
      onClick={() =>
        ref.current?.scrollIntoView({ inline: "center", behavior: "smooth" })
      }
      aria-label={ariaLabel}
      className={classNames(
        `flex flex-col ml-2 p-2 rounded border border-b-4 opacity-50 data-[selected]:opacity-100 data-[selected]:shadow-md`,
        borderColor,
      )}
    >
      <div
        className={classNames(
          "font-medium text-sm",
          hasErrors && "text-error-icon",
        )}
      >
        {header}
      </div>
      <div className="text-xs text-black/50 leading-5 whitespace-nowrap">
        {subHeader}
      </div>
    </HeadlessTab>
  );
};

export const SurveyForm = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { mode, propertyId } = useParams();
  const { mechanicId, mechanicEmail } = useLoaderData() as {
    mechanicId: string;
    mechanicEmail: string;
  };
  const { survey, propertyName } = useLoaderData() as {
    survey: SurveyFormVm;
    propertyName: string;
  };
  const navigate = useNavigate();
  const [isExitDialogOpen, setIsExitDialogOpen] = useState<boolean>(false);
  const hookForm = useForm<SurveyFormVm>({
    defaultValues: survey,
    resolver: zodResolver(SurveyFormValidation),
    mode: "all",
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = hookForm;

  watch();
  useEffect(() => {
    const { unsubscribe } = watch(() => {
      if (!Object.keys(errors).length) {
        setShowFloatingMessage(false);
      }
    });
    return () => unsubscribe();
  }, [watch, errors]);

  const [showFloatingMessage, setShowFloatingMessage] = useState(false);

  if (mode !== "extended" && mode !== "basic") {
    throw new Error("Invalid mode");
  }

  const {
    generalCount,
    generalCompletedCount,
    identificationCount,
    identificationCompletedCount,
    technicalCount,
    technicalCompletedCount,
    totalCount,
    totalCompletedCount,
  } = calculateCompleted(watch(), mode);

  const { addOpportunity } = useOpportunities();

  const isRecommendationsTab =
    (mode === "basic" && selectedIndex === 2) ||
    (mode === "extended" && selectedIndex === 3);

  const identificationHasErrors = Object.keys(errors).some((key) =>
    Object.keys(identificationErrorMessages).includes(key),
  );

  const generalHasErrors = Object.keys(errors).some((key) =>
    Object.keys(generalErrorMessages).includes(key),
  );

  return (
    <FormProvider {...hookForm}>
      <Page>
        <Header onBackClick={() => setIsExitDialogOpen(true)}>
          <div>
            <span className="font-medium">{survey.name}</span>
            <span className="inline-block ml-2 text-xs opacity-50">
              {percentageFormatter(totalCompletedCount, totalCount)} Complete
            </span>
          </div>
          <div className="text-xs opacity-80 mt-1">
            {survey.typeOfUse && `${survey.typeOfUse}. `}
            {survey.unitNumber && `Unit Number ${survey.unitNumber}`}
          </div>
        </Header>
        <FloatingErrorMessage
          show={
            Object.keys(errors).length && showFloatingMessage ? true : false
          }
          handleClose={() => setShowFloatingMessage(false)}
        />
        <form
          className="relative overflow-scroll sm:overflow-auto flex flex-col flex-grow"
          noValidate
          onKeyDownCapture={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onSubmit={handleSubmit(async (data) => {
            const elevator = await saveElevator(
              Elevator.parse({ ...data, propertyName }),
            );
            const { dateOfLastCAT1Testing, dateOfLastCAT5Testing } = elevator;
            const commonData = {
              unit: elevator.id,
              unitName: elevator.name,
              site: elevator.propertyId,
              siteName: propertyName,
              createdAt: new Date().toLocaleDateString(),
              fromId: mechanicId,
              fromEmail: mechanicEmail,
              branch: elevator.region || "",
            };
            if (data.recommendation) {
              const recommendation = repairRecommendationSchemaWithoutId.parse({
                ...commonData,
                ...data.recommendation,
              });
              await addOpportunity(recommendation);
            }
            const surveyRecommendation = dataDrivenSchemaWithoutId.parse({
              ...commonData,
              opportunity: "",
              source: "Survey",
              type: "data-driven",
              archived: false,
            });
            for (const opp of upgradeNeededOpportunities) {
              if (elevator[opp]) {
                surveyRecommendation.opportunity = columnHeaderMap[opp];
                await addOpportunity(surveyRecommendation);
              }
            }
            for (const opp of existOpportunities) {
              if (elevator[opp] === false) {
                surveyRecommendation.opportunity = columnHeaderMap[opp]
                  .replace("Does it have ", "")
                  .replace("?", "");
                await addOpportunity(surveyRecommendation);
              }
            }
            if (
              dateOfLastCAT1Testing &&
              new Date(dateOfLastCAT1Testing) < TESTING_SCHEDULE
            ) {
              surveyRecommendation.opportunity =
                "Last CAT-1 Testing completed over a year ago";
              await addOpportunity(surveyRecommendation);
            }
            if (
              dateOfLastCAT5Testing &&
              new Date(dateOfLastCAT5Testing) < TESTING_SCHEDULE
            ) {
              surveyRecommendation.opportunity =
                "CAT-5 last completed over a year ago";
              await addOpportunity(surveyRecommendation);
            }

            navigate(
              `/app/survey/${mode}/${propertyId}/${elevator.id}/success`,
            );
          })}
        >
          <TabGroup
            className="flex flex-col flex-grow items-center"
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
          >
            <TabList className="sticky top-0 left-0 z-100 flex sm:justify-center w-full mx-4 pt-2 pb-4 overflow-x-auto bg-white">
              <Tab
                header={
                  <span className="flex items-center gap-1">
                    {identificationHasErrors && (
                      <ExclamationCircleIcon className="w-4 text-error-icon" />
                    )}{" "}
                    Identification
                  </span>
                }
                ariaLabel="Identification"
                hasErrors={identificationHasErrors}
                subHeader={`${identificationCompletedCount}/${identificationCount} Completed`}
              />
              <Tab
                header={
                  <span className="flex items-center gap-1">
                    {generalHasErrors && (
                      <ExclamationCircleIcon className="w-4 text-error-icon" />
                    )}{" "}
                    General
                  </span>
                }
                ariaLabel="General"
                hasErrors={generalHasErrors}
                subHeader={`${generalCompletedCount}/${generalCount} Completed`}
              />
              {mode === "extended" && (
                <Tab
                  header="Technical"
                  ariaLabel="Technical"
                  subHeader={`${technicalCompletedCount}/${technicalCount} Completed`}
                />
              )}
              <Tab header="Recommendations" ariaLabel="Recommendations" />
            </TabList>
            <TabPanels className="p-4 py-0 w-full md:max-w-screen-md">
              <TabPanel>
                <IdentificationTab />
              </TabPanel>
              <TabPanel>
                <GeneralTab />
              </TabPanel>
              {mode === "extended" && (
                <TabPanel>
                  <TechnicalTab />
                </TabPanel>
              )}
              <TabPanel>
                <RecommendationsTab />
              </TabPanel>
            </TabPanels>
          </TabGroup>
          <div className="sticky bottom-0 flex p-4 justify-between bg-white border-t border-black/10 z-100">
            <Button
              disabled={isSubmitting}
              onClick={() => setShowFloatingMessage(true)}
              type="submit"
            >
              Submit
            </Button>
            {!isRecommendationsTab ? (
              <Button
                onClick={() => setSelectedIndex(selectedIndex + 1)}
                variant="clear"
                type="button"
              >
                Next
              </Button>
            ) : null}
          </div>
        </form>
      </Page>
      <ExitDialog
        isOpen={isExitDialogOpen}
        onDismiss={() => setIsExitDialogOpen(false)}
        onSubmit={() => navigate("/app/survey")}
      />
    </FormProvider>
  );
};
