import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

type FloatingErrorMessageProps = {
  show: boolean;
  handleClose: (e: boolean) => void;
  message?: string;
};

export const FloatingErrorMessage = ({
  show,
  handleClose,
  message,
}: FloatingErrorMessageProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(show);

  useEffect(() => {
    setIsOpen(show);
  }, [show, setIsOpen]);

  return (
    isOpen && (
      <div className="flex justify-center">
        <div className="w-11/12 absolute top-2 z-101 sm:w-mobile min-h-14 px-4 py-2 bg-rose-200 rounded shadow-2xl justify-start items-center gap-1 flex">
          <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
            <div className="text-error-dark text-sm font-medium">
              {message ? "Error" : "Required Fields Missing"}
            </div>
            <div className="text-error-dark text-xs">
              {message || "Complete highlighted fields to proceed."}
            </div>
          </div>
          <div className="flex-col justify-start items-center inline-flex">
            <div className="p-1 rounded-[48px] justify-start items-center inline-flex">
              <XMarkIcon
                onClick={() => handleClose(false)}
                className="h-6 w-6 text-black/60 hover:cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};
