import { PlusIcon } from "@heroicons/react/24/solid";
import { Field, Label as HeadlessLabel, Input } from "@headlessui/react";
import { useRef } from "react";
import classNames from "classnames";
import { getAPI } from "../api";
import { InputLabel } from "./InputLabel";
import { HelperText } from "./HelperText";
import { DeleteImgIcon } from "../views/icons";
import { ElevatorImageFields, ElevatorImage } from "src/models/elevator";

type FileUploadProps = {
  label?: string;
  helperText?: string;
  name: string;
  elevatorId: string;
  maxFiles: number;
  handleOnChange?(url: string[] | string, imageDeleted: boolean): void;
  className?: string;
  currentImages: ElevatorImage[];
  hasError?: boolean;
};

export const FileUpload = ({
  name,
  elevatorId,
  handleOnChange,
  label,
  currentImages,
  helperText,
  maxFiles,
  hasError,
}: FileUploadProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const disabled = currentImages.length === maxFiles;
  return (
    <Field>
      <InputLabel>
        <HeadlessLabel>{label}</HeadlessLabel>
      </InputLabel>
      <div className="flex flex-wrap gap-2 mt-2">
        <button
          type="button"
          className={classNames(
            "flex justify-center items-center h-16 w-16 p-5 border rounded",
            disabled
              ? "border-input"
              : hasError
                ? "border-error-icon text-error-icon"
                : "border-main hover:cursor-pointer",
          )}
          onClick={() => ref.current?.click()}
        >
          <div className="sr-only">{label}</div>
          <PlusIcon
            className={classNames(
              "w-4 h-4",
              disabled
                ? "text-input"
                : hasError
                  ? "text-error-icon"
                  : "text-main",
            )}
          />
          <Input
            ref={ref}
            name={name}
            hidden
            multiple={maxFiles > 1}
            type="file"
            disabled={disabled}
            accept="image/*"
            onClick={(e) => {
              e.currentTarget.value = "";
            }}
            onChange={async (e) => {
              if (e.target.files?.length) {
                const newImages = [];
                for (const file of e.target.files) {
                  const api = await getAPI();
                  const url = await api.addPhotoForElevator({
                    photo: file,
                    elevatorId,
                    fieldName: name as keyof ElevatorImageFields,
                  });
                  newImages.push(url);
                }
                handleOnChange?.(newImages, false);
              }
            }}
          />
        </button>
        {currentImages?.map((image) => (
          <div key={typeof image === "string" ? image : image.url}>
            <img
              key={typeof image === "string" ? image : image.url}
              src={typeof image === "string" ? image : image.url}
              alt="Unit image"
              className="w-16 h-16 object-cover rounded "
            />
            <div
              onClick={() =>
                handleOnChange?.(
                  typeof image === "string" ? image : image.url,
                  true,
                )
              }
              className="w-6 h-6 bg-[#888] rounded-[40px] shadow-lg justify-center items-center gap-2.5 inline-flex relative top-[-12px] right-[-50px] hover:cursor-pointer"
            >
              <DeleteImgIcon />
            </div>
          </div>
        ))}
      </div>
      <HelperText message={helperText || ""} />
    </Field>
  );
};
