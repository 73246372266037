import classNames from "classnames";

type BasePageProps = {
  children: React.ReactNode;
  className?: string;
};

export const Page = ({ children, className }: BasePageProps) => {
  return (
    <div
      className={classNames("h-full flex flex-col justify-between", className)}
    >
      {children}
    </div>
  );
};
