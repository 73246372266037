import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { ErrorPage } from "./common/ErrorPage";
import { AppShell } from "./common/AppShell";
import { getAPI } from "./api";
import { Elevator } from "./models/elevator.ts";
import { cloneElevator } from "./lib/helper.ts";
import { logEvent } from "firebase/analytics";
import { SWRConfig } from "swr";

const router = createBrowserRouter([
  {
    path: "",
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        loader: () => redirect("/app"),
      },
      {
        path: "login",
        loader: async () => {
          const api = await getAPI();
          try {
            await api.requireUser();
            return redirect("/app");
          } catch (error) {
            return {};
          }
        },
        async lazy() {
          const { Login } = await import("./views/Login");
          return {
            element: <Login />,
          };
        },
      },
      {
        path: "logout",
        loader: async () => {
          try {
            const api = await getAPI();
            await api.requireUser();
            await api.logout();
          } catch {
            // Do nothing
          }
          throw redirect("/login");
        },
        async lazy() {
          const { Login } = await import("./views/Login");
          return {
            element: <Login />,
          };
        },
      },
      {
        path: "forgot-password",
        async lazy() {
          const { ForgotPassword } = await import("./views/ForgotPassword");
          return {
            element: <ForgotPassword />,
          };
        },
      },
      {
        path: "app",
        element: <AppShell />,
        loader: async () => {
          try {
            const api = await getAPI();
            const user = await api.requireUser();
            return { user };
          } catch (error) {
            const resp = redirect("/login");
            throw resp;
          }
        },
        children: [
          {
            path: "",
            async lazy() {
              const { Home } = await import("./views/Home");
              return {
                element: <Home />,
              };
            },
            loader: async () => {
              const api = await getAPI();

              logEvent(api.analytics, "home_visited");

              const { displayName, email, roles } = await api.requireUser();

              if (roles.has("roles/mechanic")) {
                throw redirect("/app/copilot");
              }

              return { displayName: displayName ? displayName : email, roles };
            },
          },
          {
            path: "copilot",
            async lazy() {
              const { CopilotPage } = await import("./views/copilot");
              const api = await getAPI();
              logEvent(api.analytics, "copilot_visited");

              const { roles, id, email } = await api.requireUser();
              if (
                !roles.has("roles/alphaUser") &&
                !roles.has("roles/admin") &&
                !roles.has("roles/mechanic")
              ) {
                throw new Response("Not Found", { status: 404 });
              }
              return {
                element: <CopilotPage />,
                loader: async () => {
                  return {
                    roles,
                    mechanicId: id,
                    mechanicEmail: email,
                  };
                },
              };
            },
          },
          {
            path: "survey",
            async lazy() {
              const { SurveySelection } = await import(
                "./views/survey/SurveySelection"
              );
              return {
                element: <SurveySelection />,
                loader: async () => {
                  const api = await getAPI();

                  logEvent(api.analytics, "survey_selection_visited");

                  const { displayName, email, roles } = await api.requireUser();
                  if (
                    !roles.has("roles/alphaUser") &&
                    !roles.has("roles/admin") &&
                    !roles.has("roles/surveyor") &&
                    !roles.has("roles/mechanic")
                  ) {
                    throw new Response("Not Found", { status: 404 });
                  }
                  return {
                    displayName: displayName ? displayName : email,
                    roles,
                  };
                },
              };
            },
          },
          {
            path: "survey/:mode",
            async lazy() {
              const { SurveyInit } = await import("./views/survey/SurveyInit");
              const api = await getAPI();
              const { roles } = await api.requireUser();
              if (
                !roles.has("roles/alphaUser") &&
                !roles.has("roles/admin") &&
                !roles.has("roles/surveyor") &&
                !roles.has("roles/mechanic")
              ) {
                throw new Response("Not Found", { status: 404 });
              }
              return {
                element: <SurveyInit />,
              };
            },
          },
          {
            path: "survey/:mode/:propertyId/:elevatorId",
            async lazy() {
              const { SurveyForm } = await import("./views/survey/SurveyForm");
              const { SurveyVm } = await import("./models/survey");

              return {
                element: <SurveyForm />,
                loader: async ({ params, request }) => {
                  const { propertyId, elevatorId, mode } = params;

                  if (!propertyId || !elevatorId) {
                    throw new Error("Missing property or elevator ID");
                  }

                  if (mode !== "basic" && mode !== "extended") {
                    throw new Error("Invalid mode");
                  }

                  const api = await getAPI();

                  logEvent(api.analytics, "survey_started");

                  const startTime = new Date().getTime();
                  localStorage.setItem(
                    "survey_start_time",
                    startTime.toString(),
                  );

                  const { roles, id, email } = await api.requireUser();

                  const property = await api.getPropertyById(propertyId);

                  if (
                    !roles.has("roles/alphaUser") &&
                    !roles.has("roles/admin") &&
                    !roles.has("roles/surveyor") &&
                    !roles.has("roles/mechanic")
                  ) {
                    throw new Response("Not Found", { status: 404 });
                  }

                  const getElevatorForId = async (id: string) => {
                    const elevators = await api.getElevatorsForProperty({
                      propertyId,
                    });

                    const elevator = elevators.find(
                      (elevator) => elevator.id === id,
                    );

                    if (!elevator) {
                      // TODO: Maybe throw an error here
                      throw redirect("/app/survey");
                    }

                    return elevator;
                  };

                  const copyFrom = new URL(request.url).searchParams.get(
                    "copyFrom",
                  );

                  const elevator =
                    elevatorId !== "new"
                      ? await getElevatorForId(elevatorId)
                      : Elevator.parse({
                          id: api.generateDocumentId(),
                          propertyId,
                          propertyAddress: property.address,
                        });

                  const survey = SurveyVm.parse(
                    copyFrom
                      ? cloneElevator({
                          from: await getElevatorForId(copyFrom),
                          to: elevator,
                          mode,
                        })
                      : elevator,
                  );

                  return {
                    survey,
                    mechanicId: id,
                    mechanicEmail: email,
                    propertyName: property.name,
                  };
                },
              };
            },
          },
          {
            path: "survey/:mode/:propertyId/:elevatorId/success",
            async lazy() {
              const { SurveySuccess } = await import(
                "./views/survey/SurveySuccess"
              );
              return {
                element: <SurveySuccess />,
                loader: async ({ params }) => {
                  const { propertyId, elevatorId, mode } = params;

                  if (mode !== "basic" && mode !== "extended") {
                    throw new Error("Invalid mode");
                  }

                  if (!propertyId || !elevatorId) {
                    throw new Error("Missing property or elevator ID");
                  }

                  const api = await getAPI();

                  const endTime = new Date().getTime();
                  const startTime = localStorage.getItem("survey_start_time");
                  const timeSpent = startTime
                    ? (endTime - +startTime) / 1000
                    : "undefined";
                  logEvent(api.analytics, "survey_submitted", {
                    duration_in_seconds: timeSpent,
                  });
                  localStorage.removeItem("survey_start_time");

                  const { roles } = await api.requireUser();

                  if (
                    !roles.has("roles/alphaUser") &&
                    !roles.has("roles/admin") &&
                    !roles.has("roles/surveyor") &&
                    !roles.has("roles/mechanic")
                  ) {
                    throw new Response("Not Found", { status: 404 });
                  }
                  return {
                    propertyId,
                    elevatorId,
                    mode,
                  };
                },
              };
            },
          },
          {
            path: "contract_builder",
            async lazy() {
              const { ContractForm } = await import(
                "./views/contract_builder/ContractForm"
              );
              const api = await getAPI();

              logEvent(api.analytics, "contract_builder_visited");

              const { roles } = await api.requireUser();

              if (
                !roles.has("roles/alphaUser") &&
                !roles.has("roles/admin") &&
                !roles.has("roles/contractCreator")
              ) {
                throw new Response("Not Found", { status: 404 });
              }
              return {
                element: <ContractForm />,
              };
            },
          },
          {
            path: "admin",
            async lazy() {
              const { Admin } = await import("./views/admin/index.tsx");
              return {
                element: <Admin />,
                loader: async () => {
                  const api = await getAPI();
                  const { roles } = await api.requireUser();

                  if (
                    !roles.has("roles/alphaUser") &&
                    !roles.has("roles/admin")
                  ) {
                    throw new Response("Not Found", { status: 404 });
                  }

                  return {};
                },
              };
            },
          },
          {
            path: "contract_standards",
            async lazy() {
              const { ContractStandards } = await import(
                "./views/contract_builder/ContractStandards"
              );
              return {
                element: <ContractStandards />,
                loader: async () => {
                  const api = await getAPI();
                  const { roles } = await api.requireUser();

                  if (
                    !roles.has("roles/alphaUser") &&
                    !roles.has("roles/admin") &&
                    !roles.has("roles/contractCreator")
                  ) {
                    throw new Response("Not Found", { status: 404 });
                  }

                  return {};
                },
              };
            },
          },
          {
            path: "sales_genie",
            async lazy() {
              const { SalesGenieDashboard } = await import(
                "./views/sales_genie/SalesGenieDashboard"
              );
              return {
                element: <SalesGenieDashboard />,
              };
            },
          },
        ],
      },
      {
        path: "contract_options/:contractId",
        async lazy() {
          const { ContractSelection } = await import(
            "./views/contract_builder/ContractSelection"
          );
          return {
            element: <ContractSelection />,
            loader: async ({ params }) => {
              const { contractId } = params;

              if (!contractId) {
                throw new Error("Missing contract ID");
              }

              const api = await getAPI();
              const contract = await api.getContractById(contractId);
              if (!contract.options) {
                throw new Response("Not Found", { status: 404 });
              } else if (contract.isContractSelected) {
                throw new Error(
                  `Contract sent for signature to ${contract.customerEmail}`,
                );
              }
              return { contractId };
            },
          };
        },
      },
    ],
  },
]);

const IntelevatorSWRConfig: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <SWRConfig
      value={{
        onError: (error) => {
          // TODO: Display a toast
          console.error("SWR error", error);
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

function App() {
  return (
    <IntelevatorSWRConfig>
      <RouterProvider router={router} />
    </IntelevatorSWRConfig>
  );
}

export default App;
