import { SyntheticEvent } from "react";
import classNames from "classnames";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { Field, Label as HeadlessLabel, Input } from "@headlessui/react";

import { InputLabel } from "./InputLabel";
import { HelperText } from "./HelperText";

type TextFieldProps = {
  onChange?: (value: string, event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  name: string;
  helperText?: string;
  label?: string;
  placeholder?: string;
  value?: string | number | null;
  type: string;
  suffix?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  childClassName?: string;
  hasError?: boolean;
  clearButton?: boolean;
  labelClassName?: string;
  min?: number;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  "placeholder" | "className" | "autoComplete" | "inputMode"
>;

export const TextField = ({
  label,
  clearButton,
  onChange,
  className,
  childClassName,
  hasError,
  helperText,
  value,
  suffix,
  prefix,
  min,
  labelClassName,
  type,
  ...props
}: TextFieldProps) => {
  return (
    <Field className={classNames("text-field", className)}>
      <InputLabel>
        <HeadlessLabel className={labelClassName}>{label}</HeadlessLabel>
      </InputLabel>
      <div
        className={classNames(
          `flex px-3 gap-2 items-center h-10 align-middle border rounded bg-[--background-color] ${hasError ? "border-error-icon" : "border-input"} focus-within:ring-1 focus-within:ring-blue-300`,
          childClassName,
        )}
      >
        {prefix ? (
          typeof prefix === "string" ? (
            <span className="helper-text leading-5 text-[--helper-text-color]">
              {prefix}
            </span>
          ) : (
            prefix
          )
        ) : null}
        <Input
          className="w-full flex-grow p-0 border-none focus:ring-0 focus:outline-none bg-transparent"
          {...props}
          type={type}
          value={value ?? ""}
          onChange={(e) => onChange?.(e.target.value, e)}
          onBlur={(e) => props.onBlur?.(e)}
          min={min}
        />
        {suffix ? (
          <span className="helper-text leading-5 text-[--helper-text-color]">
            {suffix}
          </span>
        ) : null}
        {type === "text" ? (
          <button
            type="button"
            style={{
              visibility: clearButton && value ? "visible" : "hidden",
            }}
            className="mr-2 text-[--close-button-color]"
            onClick={(e) => onChange?.("", e)}
          >
            <XCircleIcon className="h-4 w-4" />
          </button>
        ) : null}
      </div>
      {helperText ? <HelperText message={helperText} /> : null}
    </Field>
  );
};
