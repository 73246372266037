import z from "zod";

import { Elevator } from "./elevator";
import { coerceUndefinedToNull } from "./utils";
import { repairRecommendationSchemaWithoutId } from "./opportunity";

export const RecommendationForm = repairRecommendationSchemaWithoutId.omit({
  unit: true,
  unitName: true,
  archived: true,
  createdAt: true,
  status: true,
  fromId: true,
  fromEmail: true,
  branch: true,
  site: true,
  siteName: true,
  customerId: true,
  customerName: true,
  value: true,
  source: true,
});

export type RecommendationForm = z.infer<typeof RecommendationForm>;

export const SurveyVm = Elevator.omit({
  machineInstallYear: true,
  controllerInstallYear: true,
  suspensionMeansInstallYear: true,
}).extend({
  machineInstallYear: coerceUndefinedToNull(z.coerce.string()),
  controllerInstallYear: coerceUndefinedToNull(z.coerce.string()),
  suspensionMeansInstallYear: coerceUndefinedToNull(z.coerce.string()),
  recommendation: RecommendationForm.nullable().default(null),
});
export type SurveyVm = z.infer<typeof SurveyVm>;
