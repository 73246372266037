import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "../../components/TextField";
import { Typeahead } from "../../components/Typeahead";
import { FileUpload } from "../../components/FileUpload";
import { useFieldOptions } from "../../fetchers/field-options";
import { RadioGroup } from "../../components/RadioGroup";
import { Textarea } from "../../components/Textarea";
import { Select } from "../../components/Select";
import { SurveyFormVm } from "./SurveyForm";
import { ErrorMessage } from "@hookform/error-message";
import { LaiErrorMessage } from "../../components/ErrorMessage";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import {
  fireServiceOptions,
  fireServiceValueFormatter,
  yearOptions as initialYearOptions,
  yesNoOptions,
  conditionOptions,
  conditionValueFormatter,
  trafficOptions,
  trafficValueFormatter,
  systemTypeValues,
  systemTypesOrder,
  isTraction,
  isHydraulic,
  tractionHydroDifferences,
} from "../../models/elevator";
import { SystemTypeOption } from "../../api/interface";

const GeneralTab = () => {
  const [yearOptions, setYearOptions] = useState<string[]>(initialYearOptions);
  const [isGeneralOpen, setIsGeneralOpen] = useState<boolean>(true);
  const [isHallwayOpen, setIsHallwayOpen] = useState<boolean>(true);
  const [isCarOpen, setIsCarOpen] = useState<boolean>(true);
  const [isMachineRoomOpen, setIsMachineRoomOpen] = useState<boolean>(true);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<SurveyFormVm>();

  const [facilityTypeQuery, setFacilityTypeQuery] = useState<string>("");
  const { options: facilityTypeOptions = [], addFieldOption: addFacilityType } =
    useFieldOptions({
      query: facilityTypeQuery,
      fieldName: "facilityType",
      setValue,
    });

  const [doorTypeQuery, setDoorTypeQuery] = useState<string>("");
  const { options: doorTypeOptions = [], addFieldOption: addDoorType } =
    useFieldOptions({
      query: doorTypeQuery,
      fieldName: "doorType",
      setValue,
    });

  const [technologyQuery, setTechnologyQuery] = useState<string>("");
  const { options: technologyOptions = [], addFieldOption: addTechnology } =
    useFieldOptions({
      query: technologyQuery,
      fieldName: "technology",
      setValue,
    });

  const { options: systemTypeOptions = [] as typeof systemTypeValues } =
    useFieldOptions({
      query: technologyQuery,
      fieldName: "systemType",
      setValue,
    });

  systemTypeOptions.sort((a, b) => {
    const aValue = systemTypesOrder.get(a as (typeof systemTypeValues)[number]);
    const bValue = systemTypesOrder.get(b as (typeof systemTypeValues)[number]);
    return aValue! - bValue!;
  });

  const [CMMQuery, setCMMQueryQuery] = useState<string>("");
  const { options: CMMOptions = [], addFieldOption: addCMM } = useFieldOptions({
    query: CMMQuery,
    fieldName: "controllerManufacturerAndModel",
    setValue,
  });

  const elevatorId = watch("id");
  const fixturePictures = watch("fixturePictures") || [];
  const machineRoomPictures = watch("machineRoomPictures") || [];
  const controllerPicture = watch("controllerPicture") || [];
  const additionalPhotos = watch("additionalPhotos") || [];
  const systemType = watch("systemType");
  const isUnitTraction = isTraction(systemType as SystemTypeOption);
  const isUnitHydraulic = isHydraulic(systemType as SystemTypeOption);

  const handleYearChange = (value: string) => {
    if (value)
      setYearOptions(
        initialYearOptions.filter((option) => option.includes(value)),
      );
    else setYearOptions(initialYearOptions);
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div
          onClick={() => setIsGeneralOpen(!isGeneralOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">General</span>
          {isGeneralOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isGeneralOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="gap-0">
              <Controller
                control={control}
                name="systemType"
                rules={{ required: true }}
                render={({ field: { ref: _, onChange, ...fieldProps } }) => (
                  <Select
                    hasError={!!errors.systemType}
                    label="System Type *"
                    options={systemTypeOptions}
                    onChange={(value) => {
                      tractionHydroDifferences.forEach((field) => {
                        setValue(field, null);
                      });
                      onChange(value);
                    }}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="systemType"
              />
            </div>
            <div className="gap-0">
              <Controller
                control={control}
                name="facilityType"
                rules={{ required: true }}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <Typeahead
                    allowAdd
                    hasError={!!errors.facilityType}
                    label="Facility Type *"
                    onQueryChange={setFacilityTypeQuery}
                    options={facilityTypeOptions}
                    onAdd={async (value) => {
                      await addFacilityType(value);
                      trigger("facilityType");
                    }}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="facilityType"
              />
            </div>
            <Controller
              control={control}
              name="enforcerDoorGibs"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Enforcer Door Gibs?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="traffic"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Traffic"
                  options={trafficOptions}
                  valueFormatter={trafficValueFormatter}
                  {...fieldProps}
                />
              )}
            />
            <div className="gap-0">
              <Controller
                control={control}
                name="condition"
                rules={{ required: true }}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <RadioGroup
                    hasError={!!errors.condition}
                    label="Condition / Wear *"
                    options={conditionOptions}
                    valueFormatter={conditionValueFormatter}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="condition"
              />
            </div>
            <Controller
              control={control}
              name="notes"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Textarea
                  helperText="Please add any helpful information about the building, parking, access to building, where to find building contact, access to unit, keys needed, machine room location and access, etc."
                  label="Location Notes"
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="additionalPhotos"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Additional Photos"
                  elevatorId={elevatorId}
                  currentImages={additionalPhotos}
                  maxFiles={10}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "additionalPhotos",
                        additionalPhotos.filter((u) => u !== url),
                      );
                    else
                      setValue("additionalPhotos", [
                        ...additionalPhotos,
                        ...url,
                      ]);
                  }}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsHallwayOpen(!isHallwayOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Hallway</span>
          {isHallwayOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isHallwayOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="gap-0">
              <Controller
                control={control}
                name="doorType"
                rules={{ required: true }}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <Typeahead
                    allowAdd
                    label="Door Type *"
                    hasError={!!errors.doorType}
                    onQueryChange={setDoorTypeQuery}
                    options={doorTypeOptions}
                    onAdd={async (value) => {
                      await addDoorType(value);
                      trigger("doorType");
                    }}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="doorType"
              />
            </div>
            <div className="gap-0">
              <Controller
                control={control}
                rules={{ required: true }}
                name="fixturePictures"
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <FileUpload
                    hasError={!!errors.fixturePictures}
                    label="Fixture Pictures *"
                    helperText="COP, Fire Service, Hoistway Access, Emergency Power"
                    elevatorId={elevatorId}
                    currentImages={fixturePictures}
                    maxFiles={5}
                    handleOnChange={(url, imageDeleted) => {
                      if (imageDeleted)
                        setValue(
                          "fixturePictures",
                          fixturePictures.filter((u) => u !== url),
                        );
                      else
                        setValue("fixturePictures", [
                          ...fixturePictures,
                          ...url,
                        ]);
                      trigger("fixturePictures");
                    }}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="fixturePictures"
              />
            </div>
            <Controller
              control={control}
              name="hoistwayAccess"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Hoistway Access?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="fireService"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Select
                  className="mb-4"
                  label="Fire Service"
                  options={fireServiceOptions}
                  valueFormatter={fireServiceValueFormatter}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsCarOpen(!isCarOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Car</span>
          {isCarOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isCarOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="gap-0">
              <Controller
                control={control}
                name="landings"
                rules={{ required: true }}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <TextField
                    hasError={!!errors.landings}
                    label="Number of Landings *"
                    type="number"
                    inputMode="numeric"
                    min={1}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="landings"
              />
            </div>
            <Controller
              control={control}
              name="frontLandings"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <TextField
                  label="Number of Front Landings"
                  type="number"
                  inputMode="numeric"
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="rearOrSideLandings"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <TextField
                  label="Number of Rear or Side Landings"
                  type="number"
                  inputMode="numeric"
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="capacityLbs"
              rules={{ min: 0 }}
              render={({ field: { ref: _, ...fieldProps } }) => (
                <TextField
                  label="Capacity (LBS)"
                  type="number"
                  inputMode="numeric"
                  min={0}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="speedFpm"
              rules={{ min: 0 }}
              render={({ field: { ref: _, ...fieldProps } }) => (
                <TextField
                  label="Speed (FPM)"
                  type="number"
                  inputMode="numeric"
                  min={0}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="LEDDownlightCeiling"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have LED Downlight Ceiling?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsMachineRoomOpen(!isMachineRoomOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Machine Room</span>
          {isMachineRoomOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isMachineRoomOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="gap-0">
              <Controller
                control={control}
                rules={{ required: true }}
                name="isThereAMachineRoom"
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <RadioGroup
                    hasError={!!errors.isThereAMachineRoom}
                    label="Is there a machine room? *"
                    options={yesNoOptions}
                    {...fieldProps}
                  />
                )}
              />
              <ErrorMessage
                as={LaiErrorMessage}
                errors={errors}
                name="isThereAMachineRoom"
              />
            </div>
            <Controller
              control={control}
              name="machineRoomPictures"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Machine Room Pictures"
                  helperText="View from Machine Room Entrance, Multiple Pictures of Layout, Picture of Machine, Data Tags"
                  elevatorId={elevatorId}
                  currentImages={machineRoomPictures}
                  maxFiles={5}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "machineRoomPictures",
                        machineRoomPictures.filter((u) => u !== url),
                      );
                    else
                      setValue("machineRoomPictures", [
                        ...machineRoomPictures,
                        ...url,
                      ]);
                  }}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="machineInstallYear"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  label="Machine Install Year"
                  {...fieldProps}
                  options={yearOptions}
                  onQueryChange={handleYearChange}
                />
              )}
            />
            <Controller
              control={control}
              name="technology"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Technology"
                  onQueryChange={setTechnologyQuery}
                  options={technologyOptions}
                  onAdd={(value) => addTechnology(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="controllerPicture"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Controller Picture"
                  elevatorId={elevatorId}
                  currentImages={controllerPicture}
                  maxFiles={5}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "controllerPicture",
                        controllerPicture.filter((u) => u !== url),
                      );
                    else
                      setValue("controllerPicture", [
                        ...controllerPicture,
                        ...url,
                      ]);
                  }}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="controllerManufacturerAndModel"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Controller Manufacturer & Model"
                  onQueryChange={setCMMQueryQuery}
                  options={CMMOptions}
                  onAdd={(value) => addCMM(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="controllerInstallYear"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  label="Controller Install Year"
                  {...fieldProps}
                  options={yearOptions}
                  onQueryChange={handleYearChange}
                />
              )}
            />
            <Controller
              control={control}
              name="dateOfLastCAT1Testing"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <TextField
                  type="date"
                  label="Date of Last CAT-1 Testing"
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="dateOfLastCAT5Testing"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <TextField
                  type="date"
                  label="Date of Last CAT-5 Testing"
                  {...fieldProps}
                />
              )}
            />
            {isUnitHydraulic && (
              <>
                <Controller
                  control={control}
                  name="softStartElectronicStarter"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Soft Start (Electronic Starter)?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="tankCooler"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Tank Cooler?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="tankHeater"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Tank Heater?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
              </>
            )}
            {isUnitTraction && (
              <Controller
                control={control}
                name="ropeGripper"
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <RadioGroup
                    label="Does it have Rope Gripper?"
                    options={yesNoOptions}
                    {...fieldProps}
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralTab;
