import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

type HeaderProps = {
  children: React.ReactNode;
  onBackClick?: () => void;
};

export const Header = ({ children, onBackClick }: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-100 flex flex-row h-min flex-shrink-0 items-center px-6 py-4 rounded-br-2xl bg-main text-white">
      <a
        onClick={() => {
          if (onBackClick) onBackClick();
          else navigate(-1);
        }}
        className="flex items-center"
      >
        <span className="mr-2.5 inline-block hover:cursor-pointer">
          <ArrowLeftIcon className="h-6 w-6" />
        </span>
      </a>
      <div>{children}</div>
    </div>
  );
};
