import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "../../components/TextField";
import { Typeahead } from "../../components/Typeahead";
import { FileUpload } from "../../components/FileUpload";
import { useFieldOptions } from "../../fetchers/field-options";
import { SurveyFormVm } from "./SurveyForm";
import { LaiErrorMessage } from "../../components/ErrorMessage";
import { ErrorMessage } from "@hookform/error-message";

const IdentificationTab = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<SurveyFormVm>();

  const [ahjQuery, setAhjQueryQuery] = useState<string>("");
  const { options: AHJOptions = [], addFieldOption: addAHJ } = useFieldOptions({
    query: ahjQuery,
    fieldName: "ahj",
    setValue,
  });

  const elevatorId = watch("id");
  const certificatePicture = watch("certificatePicture") || [];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="gap-0">
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field: { ref: _, ...fieldProps } }) => (
            <TextField
              hasError={!!errors.name}
              label={"Unit Name *"}
              type="text"
              {...fieldProps}
            />
          )}
        />
        <ErrorMessage as={LaiErrorMessage} errors={errors} name="name" />
      </div>
      <div className="gap-0">
        <Controller
          control={control}
          name="unitNumber"
          rules={{ required: true }}
          render={({ field: { ref: _, ...fieldProps } }) => (
            <TextField
              hasError={!!errors.unitNumber}
              label="Unit Number *"
              type="text"
              {...fieldProps}
            />
          )}
        />
        <ErrorMessage as={LaiErrorMessage} errors={errors} name="unitNumber" />
      </div>
      <div className="gap-0">
        <Controller
          control={control}
          name="ahj"
          rules={{ required: true }}
          render={({ field: { ref: _, ...fieldProps } }) => (
            <Typeahead
              allowAdd
              hasError={!!errors.ahj}
              label="AHJ (Authority Having Jurisdiction) *"
              onQueryChange={setAhjQueryQuery}
              options={AHJOptions}
              onAdd={async (value) => {
                await addAHJ(value);
                trigger("ahj");
              }}
              {...fieldProps}
            />
          )}
        />
        <ErrorMessage as={LaiErrorMessage} errors={errors} name="ahj" />
      </div>
      <div className="gap-0">
        <Controller
          control={control}
          name="ahjId"
          rules={{ required: true }}
          render={({ field: { ref: _, ...fieldProps } }) => (
            <TextField
              hasError={!!errors.ahjId}
              label={"AHJ # *"}
              type="text"
              {...fieldProps}
            />
          )}
        />
        <ErrorMessage as={LaiErrorMessage} errors={errors} name="ahjId" />
      </div>

      <Controller
        control={control}
        name="certificatePicture"
        render={({ field: { ref: _, ...fieldProps } }) => (
          <FileUpload
            label="Certificate Picture"
            elevatorId={elevatorId}
            handleOnChange={(url, imageDeleted) => {
              if (imageDeleted)
                setValue(
                  "certificatePicture",
                  certificatePicture.filter((u) => u !== url),
                );
              else
                setValue("certificatePicture", [...certificatePicture, ...url]);
            }}
            currentImages={certificatePicture}
            maxFiles={1}
            {...fieldProps}
          />
        )}
      />
    </div>
  );
};

export default IdentificationTab;
