import useSWR, { mutate } from "swr";
import { OptionsWithCustomInputSelect } from "../api/interface";
import { getAPI } from "../api";

const optionsForFieldFetcher = async ([_, fieldName]: [
  key: string,
  fieldName: OptionsWithCustomInputSelect,
]) => {
  const api = await getAPI();
  return api.fetchFieldOptions({
    fieldName,
  });
};

const fieldOptionsKey = "fieldOptions";

export const addFieldOption = async ({
  fieldName,
  value,
}: {
  fieldName: OptionsWithCustomInputSelect;
  value: string;
}) => {
  const api = await getAPI();
  await api.addFieldOption({
    fieldName,
    value,
  });

  // This triggers a revalidation of the options
  mutate(
    (key) => {
      return (
        Array.isArray(key) && key[0] === fieldOptionsKey && key[1] === fieldName
      );
    },
    undefined,
    {
      revalidate: true,
    },
  );
};

type UseFieldOptionsHookOptions = {
  fieldName: OptionsWithCustomInputSelect;
  setValue: (fieldName: OptionsWithCustomInputSelect, value: string) => void;
  query: string;
};

export const useFieldOptions = ({
  query = "",
  fieldName,
  setValue,
}: UseFieldOptionsHookOptions) => {
  const { data: options, isLoading } = useSWR(
    fieldName ? [fieldOptionsKey, fieldName] : null,
    optionsForFieldFetcher,
  );

  const addFieldOptionBound = async (value: string) => {
    await addFieldOption({ fieldName, value });
    setValue(fieldName, value);
  };

  const optionsFiltered = options?.filter((option) =>
    option.toLowerCase().includes(query.trim().toLowerCase()),
  );

  return {
    options: optionsFiltered,
    isLoading,
    addFieldOption: addFieldOptionBound,
  };
};
