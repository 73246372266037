type HelperTextProps = {
  message: string;
};

export const HelperText = ({ message }: HelperTextProps) => {
  return (
    <div className="helper-text pt-1 text-xs leading-5 text-[--helper-text-color]">
      {message}
    </div>
  );
};
