import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  Field,
  Label as HeadlessLabel,
} from "@headlessui/react";
import classNames from "classnames";
import { InputLabel } from "./InputLabel";
import { useState, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";

const OPTIONS_LIMIT = 50;

type Option =
  | {
      id: string;
      helperOptionText?: string;
    }
  | string;

type BaseTypeaheadProps<T extends Option | string> = {
  label: string | JSX.Element;
  onChange?: (value: T | null) => void;
  handleOnChange?: () => void;
  onQueryChange?: (query: string) => void;
  isLoading?: boolean;
  placeholder?: string;
  childClassName?: string;
  hasError?: boolean;
  value?: T | T[];
  multiple?: boolean;
  showAllOption?: boolean;
  getDisplayValue?: (option: T) => string;
  options: readonly T[];
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  "className" | "name" | "placeholder"
>;

const AddSymbol = Symbol("typeahead.add");

type TypeaheadProps<T extends Option> = BaseTypeaheadProps<T> &
  (
    | {
        allowAdd?: false;
      }
    | {
        allowAdd: true;
        addLabel?: string;
        onAdd: (query: string) => void;
      }
  );

const defaultOptionFormatter = (option: Option): string => {
  if (!option) return "";

  if (typeof option === "string") {
    return option;
  }

  if ("label" in option && typeof option.label === "string") {
    return option.label;
  }

  throw new Error(
    "Option must be a string or an object with a label property. Optionally, you can provide a custom getDisplayValue function.",
  );
};

const defaultIdExtractor = (option: Option) =>
  typeof option === "string" ? option : option?.id;

export const Typeahead = <T extends Option>({
  className,
  label,
  onChange,
  handleOnChange,
  onQueryChange: parentOnQueryChange,
  options,
  isLoading,
  value,
  placeholder,
  childClassName,
  hasError,
  showAllOption = false,
  multiple = false,
  getDisplayValue = defaultOptionFormatter,
  ...rest
}: TypeaheadProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");

  const onQueryChange = (query: string): void => {
    parentOnQueryChange?.(query);
    setQuery(query.trim());
  };

  const isOptionSelected = (
    option: T | string,
    value: T | T[] | string | undefined,
  ) => {
    if (option && value) {
      if (Array.isArray(value) && typeof option === "object") {
        return value.some((v) => {
          if (typeof v === "object" && "id" in v) {
            return v.id === option.id;
          }
          return false;
        });
      } else if (typeof option === "string" && typeof value === "string")
        return option === value;
      else if (
        !Array.isArray(value) &&
        typeof option === "object" &&
        typeof value === "object"
      )
        return option?.id === value.id;
    }
  };

  const filteredOptions = parentOnQueryChange
    ? options
    : options.filter((option) =>
        getDisplayValue(option).toLowerCase().includes(query.toLowerCase()),
      );

  return (
    <Field className={className}>
      <InputLabel>
        <HeadlessLabel>{label}</HeadlessLabel>
      </InputLabel>
      <Combobox<T | T[] | { action: typeof AddSymbol; value: string } | null>
        immediate
        multiple={multiple as false} // need to override bad HeadlessUI type inference (expects only false or undefined)
        value={value ?? null}
        onChange={(value) => {
          if (
            value &&
            typeof value === "object" &&
            "action" in value &&
            value.action === AddSymbol &&
            rest.allowAdd
          ) {
            if (!rest.addLabel && value.value.length) {
              rest.onAdd(value.value);
            } else if (rest.addLabel) {
              rest.onAdd(value.value);
            }
            onChange?.(null);
            setQuery("");
            setTimeout(() => inputRef.current?.blur(), 0);
            return;
          }
          onChange?.((value as T) ?? null);
          if (value && !multiple) setTimeout(() => inputRef.current?.blur(), 0);
          if (handleOnChange) handleOnChange();
        }}
      >
        <div
          className={classNames(
            `flex gap-2 px-3 py-2 bg-white rounded border ${hasError ? "border-error-icon" : "border-input"} focus-within:ring-1 focus-within:ring-blue-300 hover:cursor-pointer`,
            childClassName,
          )}
        >
          <ComboboxInput
            className="flex-auto w-full p-0 text-left border-0 focus:ring-0 truncate ring-0 outline-none"
            placeholder={
              showAllOption ? "All" : placeholder ?? "Select a value"
            }
            displayValue={(item?: T) => {
              if (Array.isArray(value)) {
                return value.map((v) => getDisplayValue(v)).join(", ");
              }
              return item ? getDisplayValue(item) : "";
            }}
            onBlur={() => onQueryChange("")}
            onChange={(event) => onQueryChange(event.currentTarget.value)}
            ref={inputRef}
          />
          <ComboboxButton className="flex-shrink-0">
            <span className="sr-only">{label}</span>
            <ChevronDownIcon
              className="size-5 text-white fill-black/55"
              aria-hidden="true"
            />
          </ComboboxButton>
        </div>
        <ComboboxOptions
          anchor={{
            to: "bottom",
            gap: 9,
            offset: 14,
          }}
          className="border max-h-[200px] z-20 rounded bg-white w-[calc(var(--input-width)+54px)] [--anchor-max-height:15rem] empty:hidden shadow-lg"
        >
          {rest.allowAdd &&
            !filteredOptions.some(
              (option) =>
                getDisplayValue(option).toLowerCase() === query.toLowerCase(),
            ) && (
              <ComboboxOption
                value={{ action: AddSymbol, value: query }}
                disabled={!rest.allowAdd}
                className={`p-2 border-b border-opacity-10 ${rest.addLabel ? "bg-main-light text-main" : ""} last:border-b-0data-[focus]:bg-blue-100 hover:bg-blue-100 hover:cursor-pointer`}
              >
                {rest.addLabel ?? `Add "${query}"`}
              </ComboboxOption>
            )}
          {isLoading && (
            <ComboboxOption
              disabled
              value="loading"
              className="p-2 border-b border-opacity-10 last:border-b-0data-[focus]:bg-blue-100"
            >
              Loading...
            </ComboboxOption>
          )}
          {showAllOption ? (
            <ComboboxOption
              value={null}
              className="flex items-center p-2 border-b last:border-b-0 data-[focus]:bg-blue-100 hover:cursor-pointer"
            >
              <div className="w-6"></div>
              All
            </ComboboxOption>
          ) : null}
          {filteredOptions.slice(0, OPTIONS_LIMIT).map((option) => (
            <ComboboxOption
              key={defaultIdExtractor(option)}
              value={option}
              className="p-2 border-b border-opacity-10 last:border-b-0 data-[focus]:bg-blue-100 hover:cursor-pointer"
            >
              {typeof option !== "string" && !option?.helperOptionText ? (
                <div className="flex items-center">
                  {isOptionSelected(option, value) ? (
                    <CheckIcon className="mr-2 size-4 fill-black" />
                  ) : (
                    <div className="w-6"></div>
                  )}
                  {getDisplayValue(option)}
                </div>
              ) : (
                <div className="flex items-center">
                  {isOptionSelected(option, value) ? (
                    <CheckIcon className="mr-2 size-4 fill-black" />
                  ) : (
                    <div className="w-6"></div>
                  )}
                  <div className="flex flex-col">
                    <span className="font-medium">
                      {getDisplayValue(option)}
                    </span>
                    <span className="helper-text text-sm leading-5 text-[--helper-text-color]">
                      {typeof option !== "string" && option.helperOptionText}
                    </span>
                  </div>
                </div>
              )}
            </ComboboxOption>
          ))}
          {filteredOptions.length > OPTIONS_LIMIT && (
            <span className="flex text-sm text-black/55 justify-center text-center">
              First {OPTIONS_LIMIT} out of {filteredOptions.length} shown.
            </span>
          )}
        </ComboboxOptions>
      </Combobox>
    </Field>
  );
};
