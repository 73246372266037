import {
  Field,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Label as HeadlessLabel,
} from "@headlessui/react";

import { CheckIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { InputLabel } from "./InputLabel";

type Option =
  | {
      label: string;
      id: string;
    }
  | string;

type SelectProps<T extends Option> = {
  label?: string | JSX.Element;
  onChange?: (value: T | T[] | null) => void;
  className?: string;
  name: string;
  placeholder?: string;
  value?: T | T[] | null;
  valueFormatter?: (value: T) => string | null;
  disabled?: boolean;
  hasError?: boolean;
  options: readonly T[];
  multiple?: boolean;
  title?: string;
  inputClassName?: string;
  optionClassName?: string;
  customOption?: JSX.Element;
  customOptionValue?: T;
  showAllOption?: boolean;
};

const getValue = <T extends Option>(
  value: T | null | undefined,
): string | null => {
  return typeof value === "string" ? value : value?.id ?? null;
};

const getLabel = <T extends Option>(
  value: T | T[] | null | undefined,
  valueFormatter: (value: T) => string | null,
): string | null => {
  if (Array.isArray(value)) {
    return value.length ? value.map((v) => valueFormatter(v)).join(", ") : null;
  }

  return value ? valueFormatter(value) : null;
};

const defaultValueFormatter = <T extends Option>(
  value: T | null,
): string | null => {
  if (value === null) {
    return null;
  }

  if (typeof value === "string") {
    return value;
  }

  return value.label ?? null;
};

export const Select = <T extends Option>({
  className,
  label,
  onChange,
  options,
  value,
  disabled,
  placeholder = "Select a value",
  title,
  multiple,
  inputClassName,
  hasError,
  optionClassName,
  customOption,
  customOptionValue,
  showAllOption = false,
  valueFormatter = (value) => defaultValueFormatter(value),
}: SelectProps<T>) => {
  const isSelected = Array.isArray(value)
    ? value.length > 0
    : value !== null && value !== undefined;
  return (
    <Field className={className}>
      {label ? (
        <InputLabel>
          <HeadlessLabel>{label}</HeadlessLabel>
        </InputLabel>
      ) : null}
      <Listbox
        disabled={disabled}
        value={value}
        onChange={(value) => onChange?.(value)}
        multiple={multiple}
      >
        <ListboxButton
          title={title}
          className={`flex gap-2 justify-between items-center text-left p-2 w-full data-[open]:ring-1 data-[open]:ring-blue-300 disabled:bg-black/[0.05] border rounded ${hasError ? "border-error-icon" : "border-input"} ${isSelected ? "text-inherit" : "text-gray-500"} ${inputClassName}`}
        >
          <span className="sr-only">{label}</span>
          <span aria-hidden className="flex-auto truncate overflow-hidden">
            {value === null && showAllOption
              ? "All"
              : getLabel(value, valueFormatter) ?? placeholder}
          </span>

          <ChevronDownIcon
            className="flex-shrink-0 size-5 text-white fill-black/55"
            aria-hidden="true"
          />
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          transition // This is a hacky fix to make the Select component work with the Dialog component
          className="z-20 border rounded w-[var(--button-width)] bg-white shadow-lg"
        >
          <div className="max-h-[250px]">
            {showAllOption ? (
              <ListboxOption
                value={null}
                className="flex items-center p-2 border-b last:border-b-0 data-[focus]:bg-blue-100 hover:cursor-pointer"
              >
                <div className="w-6"></div>
                All
              </ListboxOption>
            ) : null}
            {options.length ? (
              <>
                {options.map((option) => (
                  <ListboxOption
                    key={getValue(option)}
                    value={option}
                    className={`flex items-center p-2 border-b last:border-b-0 data-[focus]:bg-blue-100 hover:cursor-pointer ${optionClassName}`}
                  >
                    {Array.isArray(value) &&
                    value.some((v) => getValue(v) === getValue(option)) ? (
                      <CheckIcon className="mr-2 size-4 text fill-black" />
                    ) : (
                      <div className="w-6"></div>
                    )}
                    {valueFormatter(option)}
                  </ListboxOption>
                ))}
                {customOption && (
                  <ListboxOption
                    value={customOptionValue}
                    className={`flex items-center p-2 border-b last:border-b-0 hover:cursor-pointer`}
                    onClick={(e) => e.preventDefault()}
                  >
                    {customOption}
                  </ListboxOption>
                )}
              </>
            ) : (
              <ListboxOption
                value={null}
                className="flex items-center p-2 border-b last:border-b-0 data-[focus]:bg-blue-100 hover:cursor-pointer"
              >
                No data
              </ListboxOption>
            )}
          </div>
        </ListboxOptions>
      </Listbox>
    </Field>
  );
};
