import { Dialog } from "../../components/Dialog";
import { Button } from "../../components/Button";

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
};

const ExitDialog = ({ isOpen, onDismiss, onSubmit }: Props) => {
  return (
    <Dialog isOpen={isOpen} onClose={onDismiss}>
      <div className="max-w-96 p-6 bg-white flex-col justify-start items-start flex">
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
          <div className="self-stretch text-center text-2xl font-semibold">
            Are you sure you want to exit the survey?
          </div>
          <div className="self-stretch text-center text-input-light">
            Changes will not be saved
          </div>
          <div className="self-stretch py-1 flex-col justify-start items-center gap-2.5 flex">
            <Button variant="clear" onClick={onSubmit}>
              Yes, exit without saving
            </Button>
            <Button onClick={onDismiss}>No, continue survey</Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ExitDialog;
