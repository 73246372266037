import type { API } from "./api";
import { IAPI } from "./interface";

export type { IAPI };

let api: API | null = null;
export async function getAPI() {
  if (api) {
    return api;
  }
  const { API } = await import("./api");
  api = new API();
  if (api) {
    api.syncWorker.on(
      "imageSync",
      async ({ fbUrl, id, parentId, fieldName }) => {
        if (!api) return;
        const elevator = await api.getElevatorById(parentId);
        if (!elevator) return;
        await api.saveElevator({
          elevator: {
            ...elevator,
            [fieldName]:
              elevator[fieldName] && Array.isArray(elevator[fieldName])
                ? elevator[fieldName]?.map((p) => {
                    if (typeof p !== "string" && p.id === id) {
                      return { url: fbUrl, id: null };
                    }
                    return p;
                  })
                : elevator[fieldName],
          },
        });
      },
    );
  }
  return api;
}
