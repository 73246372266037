import useSWR from "swr";
import { getAPI } from "../api";
import { OpportunityWithoutId } from "../models/opportunity";

const opportunitiesKey = "opportunities";

const opportunitiesFetcher = async (_: [key: string]) => {
  const api = await getAPI();
  return api.getOpportunities();
};

export const addOpportunity = async (opportunity: OpportunityWithoutId) => {
  const api = await getAPI();
  await api.addOpportunity({
    opportunity,
  });
};

export const useOpportunities = () => {
  const { data: opportunities, isLoading } = useSWR(
    [opportunitiesKey],
    opportunitiesFetcher,
  );

  return {
    opportunities,
    isLoadingOpportunities: isLoading,
    addOpportunity,
  };
};
