import { BonusIcon } from "../views/icons";

export const BonusBanner = () => {
  return (
    <div className="mt-2 p-1 bg-main-light rounded justify-start items-center gap-2 inline-flex">
      <BonusIcon className="" />
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
        <div className="text-bonus text-sm font-medium">Earn a cash bonus!</div>
        <div className="self-stretch text-bonus text-xs font-normal">
          Submit a repair suggestion that gets implemented, and you’ll receive a
          cash bonus. Share your insights to help improve our elevators and earn
          extra cash!
        </div>
      </div>
    </div>
  );
};
