import classNames from "classnames";

type InputLabelProps = {
  children?: React.ReactNode;
  className?: string;
};

export const InputLabel = ({ children, className }: InputLabelProps) => {
  return (
    <div className={classNames("font-semibold text-sm leading-8", className)}>
      {children}
    </div>
  );
};
