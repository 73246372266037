import classNames from "classnames";

export const DeleteImgIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className)}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="CloseFilled">
      <path
        id="Vector"
        d="M11.0834 3.73925L10.2609 2.91675L7.00008 6.17758L3.73925 2.91675L2.91675 3.73925L6.17758 7.00008L2.91675 10.2609L3.73925 11.0834L7.00008 7.82258L10.2609 11.0834L11.0834 10.2609L7.82258 7.00008L11.0834 3.73925Z"
        fill="white"
      />
    </g>
  </svg>
);
