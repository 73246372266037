import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "./Spinner";

type ButtonProps = {
  children: React.ReactNode;
  variant?: "primary" | "light" | "clear";
  rounded?: "lg" | "full";
  to?: string;
  isLoading?: boolean;
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "type" | "disabled" | "className"
>;

export const Button = ({
  children,
  className,
  variant = "primary",
  rounded = "full",
  to,
  isLoading,
  ...props
}: ButtonProps) => {
  const button = (
    <button
      type="submit"
      data-variant={variant}
      className={classNames(
        "button flex items-center justify-center min-h-12 py-2 px-4 hover:cursor-pointer disabled:opacity-50",
        rounded === "lg" ? "rounded-lg" : "rounded-full",
        className,
      )}
      {...props}
    >
      {children}
      {isLoading ? <Spinner className="ml-2" /> : null}
    </button>
  );

  if (!to) {
    return button;
  }

  return <Link to={to}>{button}</Link>;
};
