import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
  CloseButton,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

type DialogProps = {
  isOpen: boolean;
  title?: string;
  children?: React.ReactNode;
  backdropDismiss?: boolean;
  onClose: () => void;
  "data-testid"?: string;
};

export const Dialog = ({
  isOpen,
  onClose: parentOnClose,
  backdropDismiss,
  title,
  children,
  ...rest
}: DialogProps) => {
  const onClose = () => {
    if (typeof backdropDismiss === "boolean" && !backdropDismiss) {
      return;
    }

    parentOnClose();
  };

  return (
    <HeadlessDialog open={isOpen} onClose={onClose} className="relative z-101">
      <DialogBackdrop className="fixed inset-0 bg-black/70" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg bg-white card flex flex-col border rounded-md max-h-[98vh] overflow-clip border-black/10 shadow-lg">
          {title ? (
            <DialogTitle className="card-header flex items-center p-4 bg-main-light text-base font-semibold data-[disabled]:text-gray-500 data-[disabled]:bg-gray-100 justify-between">
              {title}
              <CloseButton
                className="w-6 h-6 border-box inline-block hover:cursor-pointer"
                as={XMarkIcon}
                onClick={parentOnClose}
              />
            </DialogTitle>
          ) : null}
          <div
            className="flex flex-col card-body p-4 gap-4 bg-white overflow-y-scroll"
            data-testid={rest["data-testid"]}
          >
            {children}
          </div>
        </DialogPanel>
      </div>
    </HeadlessDialog>
  );
};
