import {
  Field,
  Radio,
  RadioGroup as HeadlessRadioGroup,
} from "@headlessui/react";
import { InputLabel } from "./InputLabel";
import classNames from "classnames";
import { Fragment } from "react/jsx-runtime";

type Option =
  | {
      label: string;
      id: string | boolean;
    }
  | string;

type RadioGroupProps<T extends Option> = {
  label?: string;
  onChange?: (value: T | null) => void;
  name: string;
  className?: string;
  optionClassName?: string;
  labelClassName?: string;
  value?: T | null;
  options: readonly T[];
  valueFormatter?: (value: T) => string | null;
  hasError?: boolean;
};

const getValue = <T extends Option>(
  value: T | null | undefined,
): string | boolean | null => {
  return typeof value === "string" ? value : value?.id ?? null;
};

const defaultValueFormatter = <T extends Option>(
  value: T | null,
): string | null => {
  if (value === null) {
    return null;
  }

  if (typeof value === "string") {
    return value;
  }

  return value.label ?? null;
};

export const RadioGroup = <T extends Option>({
  name,
  label,
  onChange,
  options,
  className,
  optionClassName,
  labelClassName,
  hasError,
  value,
  valueFormatter = (value) => defaultValueFormatter(value),
}: RadioGroupProps<T>) => {
  return (
    <div className={classNames(className)}>
      <InputLabel>{label}</InputLabel>
      <HeadlessRadioGroup
        value={value}
        onChange={(value) => onChange?.(value)}
        aria-label={label}
        className="flex justify-between"
        name={name}
      >
        {options.map((option) => (
          <Field key={defaultValueFormatter(option)} as={Fragment}>
            <Radio
              value={getValue(option)}
              className={classNames(
                optionClassName,
                `group text-center grow border ${hasError ? "border-error-icon" : "border-input"} border-l-0 first:border-l p-2 text-sm w-20 leading-6 first:rounded-l-md [&:nth-last-child(2)]:rounded-r-md hover:cursor-pointer bg-white data-[checked]:bg-main data-[checked]:text-white`,
              )}
            >
              <InputLabel
                className={classNames("hover:cursor-pointer", labelClassName)}
              >
                {valueFormatter(option)}
              </InputLabel>
            </Radio>
          </Field>
        ))}
      </HeadlessRadioGroup>
    </div>
  );
};
