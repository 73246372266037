import { SyntheticEvent } from "react";
import { Field, Label as HeadlessLabel } from "@headlessui/react";
import classNames from "classnames";

import { InputLabel } from "./InputLabel";
import { HelperText } from "./HelperText";

type TextareaProps = {
  onChange?: (value: string, event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  name: string;
  helperText?: string;
  label: string;
  type?: string;
  value?: string | number | null;
} & Pick<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  "placeholder" | "className" | "rows"
>;

export const Textarea = ({
  label,
  onChange,
  className,
  helperText,
  value,
  ...props
}: TextareaProps) => {
  return (
    <Field className={classNames("text-field", className)}>
      <InputLabel>
        <HeadlessLabel>{label}</HeadlessLabel>
      </InputLabel>
      <textarea
        className="block h-40 w-full border border-input rounded focus:ring-1 focus:ring-blue-300 disabled:bg-black/[0.05]"
        {...props}
        value={value ?? ""}
        onChange={(e) => onChange?.(e.target.value, e)}
        onBlur={(e) => props.onBlur?.(e)}
      />
      {helperText ? <HelperText message={helperText} /> : null}
    </Field>
  );
};
