const className =
  "flex items-center pt-1 text-xs font-medium rounded text-error-dark";

export const LaiErrorMessage = ({
  children,
  noFloating,
}: {
  children: React.ReactNode;
  noFloating?: boolean;
}) => (
  <div
    className={
      noFloating ? `mt-1 px-4 py-2 bg-error-light ${className}` : className
    }
  >
    {children}
  </div>
);
