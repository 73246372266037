/**
 * Elevator's SystemType is an enum of all the system types an elevator can have. - Legacy code, along with incentives and refuseSensorBox
 */
export const HydroSystemType = {
  HYDRO_DIRECT: "Hydro Direct",
  TELESCOPIC: "Telescopic",
  ROPED_HYDRO: "Roped Hydro",
  DUMBWAITER_HYDRO: "Dumbwaiter Hydro",
  VPL_HYDRO: "Vertical Platform Lift (VPL) Hydro",
  HANDICAP_LIFT_HYDRO: "Handicap Lift Hydro",
  TWIN_POST_HOLE_LESS: "Twin Post Hole-less",
} as const;

export const TractionSystemType = {
  TRACTION_GEARED: "Traction Geared",
  GEARLESS: "Gearless",
  DUMBWAITER_TRACTION: "Dumbwaiter Traction",
  VPL_TRACTION: "Vertical Platform Lift (VPL) Traction",
  HANDICAP_LIFT_TRACTION: "Handicap Lift Traction",
} as const;

export const OtherSystemType = {
  ESCALATOR: "Escalator",
  MOVING_WALK: "Moving Walk",
  WHEELCHAIR_LIFT: "Wheelchair Lift (WCL)",
} as const;

export const SystemType = {
  ...HydroSystemType,
  ...TractionSystemType,
  ...OtherSystemType,
} as const;

export type SystemTypeOption = (typeof SystemType)[keyof typeof SystemType];

/**
 * ElevatorCondition is an enum of all
 * conditions that an elevator can be in.
 */
export const ElevatorCondition = {
  /** Good Condition */
  GOOD: "good",
  /** Fair Condition */
  FAIR: "fair",
  /** Poor Condition */
  POOR: "poor",
} as const;
export type ElevatorCondition =
  (typeof ElevatorCondition)[keyof typeof ElevatorCondition];

/**
 * Frequency.
 */
export const Frequency = {
  MONTHLY: "monthly",
  BIMONTHLY: "bimonthly",
  QUARTERLY: "quarterly",
  SIX_TIMES_A_YEAR: "6_times_a_year",
  EIGHT_TIMES_A_YEAR: "8_times_a_year",
} as const;
export type Frequency = (typeof Frequency)[keyof typeof Frequency];

export const RiskLevel = {
  NONE: "None",
  EXTREMELY_LOW: "Extremely Low",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  EXTREMELY_HIGH: "Extremely High",
} as const;
export type RiskLevel = (typeof RiskLevel)[keyof typeof RiskLevel];

/**
 * Region is an enum of all the regions that contracts can be completed in.
 * The region is used to calculate the burden rate for a contract.
 */
export const Region = {
  GSO: "GSO",
  UNI: "UNI",
  MED: "MED",
  CLT: "CLT",
  ERO: "ERO",
  ROA: "ROA",
  COL: "COL",
  RIC: "RIC",
  RDU: "RDU",
} as const;
export type Region = (typeof Region)[keyof typeof Region];

export type RegionBurdenRate = { [key in Region]: number };

export type OpportunityRiskLevel = {
  opportunity: Record<RiskLevel, number>;
  risk: Record<RiskLevel, number>;
};
